"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WindowMessageService = void 0;
const messaging_common_1 = require("@guided-methods/messaging-common");
class WindowMessageService {
    constructor(otherWnd, url, logger, tag = false) {
        this.otherWnd = otherWnd;
        this.url = url;
        this.logger = logger;
        this.tag = tag;
        this.handlers = [];
        this.wrapHandler = (handler) => (ev) => {
            let result;
            if (ev.source.location !== this.otherWnd.location)
                return;
            try {
                if (!(this.url.includes('localhost') || this.url.includes('127.0.0.1')))
                    this.logger.debug({ message: `Received message from window ${this.url}: ${JSON.stringify(ev)}`, ...ev.data.context });
                result = (0, messaging_common_1.validate)(messaging_common_1.GuideMessage, ev.data);
            }
            catch (e) {
                this.logger.debug({ message: `Unknown message format from window ${this.url}, skipping, ${e.message}`, ...ev.data.context });
            }
            result && handler(result);
        };
    }
    async send(message) {
        if (this.otherWnd === window) {
            this.logger.warn(`A window is trying to send message to itself: ${JSON.stringify(message)}`);
            return;
        }
        try {
            if (!(this.url.includes('localhost') || this.url.includes('127.0.0.1')))
                this.logger.debug({ message: `Sending message to window ${this.url}: ${JSON.stringify(message)}`, ...message.context });
            const transformed = (0, messaging_common_1.substituteChannelsWithId)(message, this.tag);
            this.otherWnd.postMessage(transformed, this.url);
        }
        catch (e) {
            this.logger.error({ message: `Failed to send message to window ${this.url} with channel ${message.to}`, ...message.context }, e);
            throw e;
        }
    }
    onGuideMessage(handler) {
        const wrapped = this.wrapHandler(handler);
        this.handlers.push(wrapped);
        window.addEventListener('message', wrapped);
        return () => {
            const handlers = this.handlers.filter(h => h === wrapped);
            this.handlers = this.handlers.filter(h => h === wrapped);
            handlers.forEach(h => window.removeEventListener('message', h));
        };
    }
}
exports.WindowMessageService = WindowMessageService;
