"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IframeContainerLoader = void 0;
const react_1 = __importStar(require("react"));
const react_router_1 = require("react-router");
const messaging_common_1 = require("@guided-methods/messaging-common");
const use_messages_1 = require("../use-messages");
const iframe_container_1 = require("./iframe-container");
const host_context_1 = require("../host-context");
const with_timeout_1 = require("@guided-methods/messaging-common/lib/transport/with-timeout");
const models_1 = require("../../models");
const window_message_service_1 = require("../../services/window-message-service");
const url_helpers_1 = require("../../utils/url-helpers");
const statusController = (timeout, dispatch) => (0, with_timeout_1.withTimeout)(timeout, () => dispatch('error'), (statusMessage, _, r) => {
    if (statusMessage.type === messaging_common_1.GuideMessageType.EndChannel) {
        dispatch('completed');
    }
    else if (statusMessage.type === messaging_common_1.GuideMessageType.Data) {
        switch (statusMessage.data.type) {
            case messaging_common_1.ChunkStatusMessageType.ChunkLoaded:
                dispatch('loaded');
                break;
            case messaging_common_1.ChunkStatusMessageType.ChunkError:
            case messaging_common_1.ChunkStatusMessageType.ChunkLoadingFailed:
                dispatch('error');
                break;
        }
    }
    return r.next();
});
const subscription = (messageService, channels, timeout, logger, setStartTime) => (router, dispatch) => {
    messageService.send(channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, {}, {
        type: models_1.BrowserRunnerStatusMessageType.IframeHostReady,
    });
    return [
        router.subscribe(channels.loadChunkChannel, (m, transport, r) => {
            if (m.type !== messaging_common_1.GuideMessageType.Data || m.data.type !== messaging_common_1.ChunkType.View) {
                return r.next();
            }
            setStartTime(new Date());
            logger.debug({
                message: 'iframe-container-loader router.subscribe called',
                mode: messaging_common_1.LogMode.Framework,
                layer: messaging_common_1.LogLayerNames.Performance,
            });
            const { executionId, instanceId } = m.data;
            const controller = router.subscribe(m.data.statusChannel, statusController(timeout, (type) => dispatch({ type, executionId, instanceId })));
            dispatch({
                type: 'load',
                message: m,
                transport,
                router: r,
                unsubscribe: () => router.unsubscribe(m.data.statusChannel, controller),
            });
            return Promise.resolve();
        }),
    ];
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'load':
            return [
                {
                    ...action,
                    loading: true,
                },
                ...state,
            ];
        case 'loaded':
            return state.map((frame) => frame.message?.data.instanceId === action.instanceId ? { ...frame, loading: false } : frame);
        case 'completed':
            state
                .filter((frame) => frame.message?.data.instanceId === action.instanceId)
                .forEach((f) => { if (f?.unsubscribe)
                f?.unsubscribe(); });
            return state.filter((frame) => frame.message?.data.instanceId !== action.instanceId);
        case 'error':
            state
                .filter((frame) => frame.message?.data.executionId === action.executionId)
                .forEach((f) => { if (f?.unsubscribe)
                f?.unsubscribe(); });
            return state.filter((frame) => frame.message?.data.executionId !== action.executionId);
    }
};
const IframeContainerLoader = ({ invisibleContainerClassName, containerClassName, visibleIframeClassName, invisibleIframeClassName, statusKeepAliveTimeoutMs, bucketUrl, }) => {
    const [reqId, setRequestId] = react_1.default.useState();
    const { requestId } = (0, react_router_1.useParams)();
    const [startTime, setStartTime] = (0, react_1.useState)(new Date());
    const nothingLoadedStateIndex = -1;
    const [currentlyLoadedStateIndex, setCurrentlyLoadedStateIndex] = (0, react_1.useState)(nothingLoadedStateIndex);
    const [state, dispatch] = react_1.default.useReducer(reducer, []);
    react_1.default.useEffect(() => {
        setRequestId(requestId);
    }, []);
    setRequestId;
    react_1.default.useEffect(() => {
        setCurrentlyLoadedStateIndex(state.findIndex((s) => !s.loading));
    }, [JSON.stringify(state)]);
    const { channels, messageService, loadChunkControllerFactory, logger } = (0, react_1.useContext)(host_context_1.HostContext);
    if (messageService && channels && logger)
        (0, use_messages_1.useMessages)(subscription(messageService, channels, statusKeepAliveTimeoutMs, logger, setStartTime), dispatch);
    return (react_1.default.createElement("div", { className: currentlyLoadedStateIndex === nothingLoadedStateIndex ? invisibleContainerClassName : containerClassName }, [
        ...state.map((frame, i) => (react_1.default.createElement(iframe_container_1.IframeContainer, { key: frame.message?.data.instanceId ?? NaN, onLoad: () => {
                const endtime = +new Date() - +startTime;
                logger?.debug({
                    message: `iframe-container-loader onLoad called ${endtime} ms`,
                    mode: messaging_common_1.LogMode.Framework,
                    layer: messaging_common_1.LogLayerNames.Performance,
                    executionDuration: endtime,
                });
            }, onWindowReady: (wnd) => {
                if (!logger) {
                    console.error("No logger setup. Aborting");
                    return;
                }
                if (!loadChunkControllerFactory) {
                    logger.error("No loadChunkControllerFactory defined. Aborting");
                    return;
                }
                if (!frame?.message || !frame?.transport || !frame?.router) {
                    logger.error("Incomplete frame provided. Aborting");
                    return;
                }
                logger.debug({
                    message: `iframe-container-loader onWindowReady called ${+new Date() - +startTime} ms`,
                    mode: messaging_common_1.LogMode.Framework,
                    layer: messaging_common_1.LogLayerNames.Performance,
                    executionDuration: +new Date() - +startTime,
                });
                const containerProvider = (loadData) => Promise.resolve({
                    messageTransport: new window_message_service_1.WindowMessageService(wnd, (0, url_helpers_1.makeFullUrl)(loadData.url, bucketUrl), (0, messaging_common_1.enrichLogger)(logger, { layer: 'transport' })),
                });
                const controller = loadChunkControllerFactory.controller(containerProvider);
                controller(frame.message, frame.transport, frame.router);
            }, url: (0, url_helpers_1.makeFullUrl)(frame.message?.data.url ?? "", bucketUrl), visibilityClassName: (currentlyLoadedStateIndex === i) ? visibleIframeClassName : invisibleIframeClassName }))),
    ]));
};
exports.IframeContainerLoader = IframeContainerLoader;
