"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupPreconditionsEvaluator = void 0;
const messaging_common_1 = require("@guided-methods/messaging-common");
const operations_1 = require("@guided-methods/operations");
const uuid_1 = require("uuid");
const setupPreconditionsEvaluator = (services, options) => {
    return async (command) => {
        if (command.type !== 'Once') {
            throw new Error('Not supported');
        }
        const defaultTimeout = 30000;
        const conditionResults = [];
        let processedConditionsCount = 0;
        const responseChannel = (0, messaging_common_1.createChannel)(operations_1.ConditionEvalResponse, (0, messaging_common_1.combine)(options.rootChannelId, (0, uuid_1.v4)()));
        const { requestId, conditions } = command.params;
        let timer = setTimeout(() => {
            clearTimeout(timer);
            services?.router?.unsubscribe(responseChannel);
            services.logger.warn({ message: `Preconditions could not be evaluated due to communication problems. Aborting`, mode: messaging_common_1.LogMode.Service });
            command.done(requestId, [], new Error('Preconditions could not be evaluated due to communication problems'));
        }, defaultTimeout);
        services.router.subscribe(responseChannel, (msg) => {
            if (msg.type === messaging_common_1.GuideMessageType.KeepAlive) {
                if (timer)
                    timer.refresh;
                return Promise.resolve();
            }
            if (msg.type === messaging_common_1.GuideMessageType.EndChannel) {
                if (timer)
                    clearTimeout(timer);
                return Promise.resolve();
            }
            if (msg.type !== messaging_common_1.GuideMessageType.Data) {
                services.logger.warn({ message: "Unexpected type of response from pre-condition resolving. Ignored.", mode: messaging_common_1.LogMode.Service });
                return Promise.resolve();
            }
            services.logger.debug({ message: `Precondition evaluation response:  ${msg.type}/${msg.data.type}: ${processedConditionsCount + 1}/${conditions.length}`, mode: messaging_common_1.LogMode.Service });
            switch (msg.data.type) {
                case operations_1.OperationUpstreamMessageType.OperationResult:
                    const { contractType, operationReferenceVersion, operationReference } = msg.context;
                    conditionResults.push({
                        operationIdentification: { contractType, operationReference, operationReferenceVersion },
                        result: msg.data.result,
                    });
                    processedConditionsCount = processedConditionsCount + 1;
                    break;
                case operations_1.OperationUpstreamMessageType.RequestResolvingStarted:
                case operations_1.OperationUpstreamMessageType.OperationCompleted:
                case operations_1.OperationUpstreamMessageType.OperationLoaded:
                case operations_1.OperationUpstreamMessageType.ResponseResolvingFinished:
                case operations_1.OperationUpstreamMessageType.ResponseResolvingStarted:
                case operations_1.OperationUpstreamMessageType.RequestResolvingFinished:
                case operations_1.OperationUpstreamMessageType.OperationLoadingStarted:
                    break;
                case operations_1.OperationUpstreamMessageType.OperationStopped:
                    conditionResults.push({
                        operationIdentification: { contractType, operationReference, operationReferenceVersion },
                        result: {
                            type: 'Error',
                            readoutError: null,
                            errorMessage: msg.data.reason ?? 'Not evaluated',
                        },
                    });
                    break;
                case operations_1.OperationUpstreamMessageType.OperationLoadingFailed:
                case operations_1.OperationUpstreamMessageType.RequestResolvingFailure:
                case operations_1.OperationUpstreamMessageType.ResponseResolvingFailure:
                case operations_1.OperationUpstreamMessageType.OperationError:
                    conditionResults.push({
                        operationIdentification: { contractType, operationReference, operationReferenceVersion },
                        result: {
                            type: 'Error',
                            readoutError: null,
                            errorMessage: msg.data.errorMessage ?? 'Not evaluated',
                        },
                    });
                    break;
                default:
                    break;
            }
            const createErrorResult = (cond, errorMessage = "") => ({
                operationIdentification: {
                    operationReference: cond.operationReference,
                    operationReferenceVersion: cond.operationReferenceVersion,
                    contractType: cond.contractType,
                },
                result: {
                    type: 'Error',
                    readoutError: null,
                    errorMessage: errorMessage ?? 'Not evaluated',
                },
            });
            if (processedConditionsCount >= conditions.length) {
                services.router.unsubscribe(responseChannel);
                clearTimeout(timer);
                const conditionResultsIncludingFailedOperations = conditions.map(cond => conditionResults.find((result) => isSameOperation(cond, result.operationIdentification)) ??
                    createErrorResult(cond));
                const errorOccurred = conditionResultsIncludingFailedOperations.find(r => r.result.type === 'Error');
                command.done(requestId, conditionResultsIncludingFailedOperations, errorOccurred ? new Error('Could not evaluate all conditions') : undefined);
            }
            return Promise.resolve();
        });
        function isSameOperation(l, r) {
            return (l.operationReference === r.operationReference &&
                l.operationReferenceVersion === r.operationReferenceVersion &&
                l.contractType.toUpperCase() === r.contractType.toUpperCase());
        }
        conditions.forEach((cond) => {
            services.messageService.send(operations_1.conditionEvalChannel, messaging_common_1.GuideMessageType.Data, { ...command.params.context, timeout: options.retryTimeout ?? defaultTimeout }, {
                initialData: {
                    condition: cond.operationReference,
                },
                responseChannel,
            });
        });
    };
};
exports.setupPreconditionsEvaluator = setupPreconditionsEvaluator;
